<template>
	<div class="collection">
		<div class="heading">
			<p>{{ $t("My Collection") }}</p>
		</div>
		<div class="button_style">
			<!-- <el-button style="background: #218da0; color: white">
				{{ $t("Empty") }}
			</el-button> -->
		</div>
		<div class="rightSide">
			<div class="rightSide_inner">

				<div class="rightSide_inner_first" v-for="(item,index) in tableData">
					<div>
						<img :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
							style="width: 356px; height: 360px" />
					</div>
					<div class="rightSide_inner_first_bottom">
						<div class="prod_title">
							<span style="color: #000000; font-size: 14px; margin-right: 5px">{{item.item_no}}</span>
							<div>
								<i class="el-icon-document-copy"></i>
							</div>
						</div>
						<div class="prod_title">
							<p style="color: #000000; font-size: 15px; margin-right: 5px">
								{{item.item_name}}
							</p>
						</div>
						<div class="prod_title">
							<div style="font-size: 14px; color: #000000">{{ $t("PURCHASE PRICE") }}:</div>
							<div style="font-size: 14px; color: #d92524; margin-left: 5px">
								￥{{item.base_price}}
							</div>
							<div style="font-size: 14px; color: #000000; margin-left: 5px">
								{{ $t("RETAIL PRICE") }}:
							</div>
							<div style="font-size: 14px; color: #d92524; margin-left: 5px">
								￥{{item.sale_price}}
							</div>
						</div>
						<div class="btn">
							<el-button style="color: #218da0" @click="delRow(item)"> {{$t('Delete')}} </el-button>
							<el-button style="color: #218da0" @click="click2url(item)"> {{$t('Details')}} </el-button>
						</div>
						<!-- <div class="prod_handle">
							<div class="prod_handle_add">
								<p class="prod_handle_title">马上抢</p>
								<div class="change_num">
									<el-input-number v-model="num" @change="handleChange" :min="1" :max="10"
										label="描述文字"></el-input-number>
								</div>
							</div>
						</div> -->
						
						
					</div>

				</div>
				
				<div style="text-align: center;">
					<el-pagination
					  :page-size="pagesData.pageSize"
					  layout="prev, pager, next"
					  :total="pagesData.total"
					   @current-change="selPage"
					  >
					</el-pagination>
				</div>
				
			</div>
			
			
			
		</div>
	</div>
</template>
<script>
	import {
		getListData,
		delCollect
	} from "@/api/goods/collect";
	export default {
		components: {},
		data() {
			return {
				tableData: [],
				requestParams: {
					chooseData: "",
				},
				num: 1,
				pagesData: {
					page: 1,
					pageSize: 20,
					total:100,
				}
			};
		},
		//创建
		created() {
			this.getListData()
		},
		methods: {
			//当前页面
			selPage(val){
				//console.log(`当前页: ${val}`);
				this.pagesData.page = parseInt(val)
				this.getListData()
			},
			//删除一条
			delRow(row) {
				//console.log(row)
				delCollect(row.item_no).then((res) => {
					this.$message.success(this.$t('Deleted successfully'));
					this.getListData();
				})
			},
			//点击2url
			click2url(row) {
				let {
					href
				} = this.$router.resolve({
					path: "/web/goodsDetail",
					query: {
						id: row.item_no,
					},
				});
				window.open(href);
			},
			getListData() {
				let queryData = {}
				queryData.page = this.pagesData.page;
				queryData.pageSize = this.pagesData.pageSize;
				getListData(queryData).then((res) => {
					let resApiData = res.data.data;
					console.log(resApiData)
					let resData = this.filterData(resApiData.data);
					this.tableData = resData;
					this.pagesData.total = resApiData.total;
					this.pagesData.pageSize = resApiData.page_size;
					this.pagesData.page = resApiData.page;
				})
			},
			
			filterData(data) {
				for (let key in data) {
					if (this.$i18n.locale == "zh-CN") {
					  data[key]["item_name"] = data[key]["item_name"];
					} else {
					  data[key]["item_name"] = data[key]["item_en"];
					}
				}
				return data
			},
			handleChange(value) {},
		},
	};
</script>
<style lang="scss" scoped>
	.collection {
		width: 1320px;
		margin: 15px 0 0 15px;
		background-color: #ffffff;
		height: 786px;
		overflow: scroll;
	}

	.rightSide {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		margin-top: 20px;

		&_inner {
			width: 1218px;
			display: grid;
			grid-template-columns: 356px 356px 356px;
			grid-template-rows: 519px 519px;
			grid-row-gap: 35px;
			grid-column-gap: 35px;

			&_first {
				width: 358px;
				height: 509px;
				border: 1px solid #fafafa;

				&_bottom {
					width: 356px;
					height: 154px;
					background-color: #ffffff;
					margin-top: -3px;
					border: 1px solid #ddedf0;
				}
			}
		}
	}

	.heading {
		width: 1218px;
		height: 50px;
		font-size: 30px;
		color: #333333;
		margin: 20px 0 0 35px;
	}

	.prod_title {
		width: 356px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.change_num {
		width: 356px;
		height: 37px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.btn {
		width: 356px;
		height: 37px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.prod_handle {
		width: 356px;
		height: 40px;
		background-color: #ddedf0;

		&_add {
			width: 200px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
		}

		&_title {
			color: #218da0;
			width: 150px;
			font-size: 15px;
		}
	}

	.button_style {
		width: 1218px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
</style>
<style lang="scss">
	.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #218da0; //修改后的背景图颜色
		color: #fff;
	}
</style>
